import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @mui/material components
import Grid from "@mui/material/Grid";

function GridContainer({ ...props }) {
  const { children, sx, ...rest } = props;
  return (
    <Grid container {...rest} sx={sx}>
      {children}
    </Grid>
  );
}

GridContainer.defaultProps = {
  sx: {},
};

GridContainer.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};

export default GridContainer;
