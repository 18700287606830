import React from "react";
import { Helmet } from "react-helmet";

function SuperAdminDashboard() {
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <h1>Welcome to Super Admin Dashboard!</h1>
    </>
  );
}
export default SuperAdminDashboard;
