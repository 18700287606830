import React from "react";
import { Stack, Typography } from "@mui/material";

export function AppDownloadSection({ sx, appDownloadUrl, variant = "big" }) {
  return (
    <Stack
      sx={{
        padding: "20px 0",
        ...sx,
      }}
      rowGap={"24px"}
    >
      <Typography
        sx={{
          fontFamily: "Open Sans, sans-serif",
          fontSize: "24px",
          fontWeight: "600",
          lineHeight: "28.01px",
          textAlign: { xs: "center", sm: "left" },
        }}
      >
        Download the app now
      </Typography>
      <Stack
        gap={"16px"}
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
        }}
      >
        <a href={appDownloadUrl} target="_blank">
          <img
            src={"/get_on_apple_store1.png"}
            alt="click here to download elevmi on apple device"
            style={{
              width: "167px",
            }}
          />
        </a>
        <a href={appDownloadUrl} target="_blank">
          <img
            src={"/get_on_play_store1.png"}
            alt="click here to download elevmi on android device"
            style={{
              width: "189px",
            }}
          />
        </a>
      </Stack>
      {variant === "small" && (
        <Typography
          sx={{
            color: "#49454E",
            fontFamily: "'Source Sans 3', sans-serif",
            fontSize: "12px",
            letterSpacing: "0.4px",
            lineHeight: "19.92px",
            textAlign: "center",
            width: "372px",
          }}
        >
          The Apple logo is a trademark of Apple Inc., registered in the United
          States and other countries. App Store is a service mark of Apple Inc.
          registered in the United States and other countries. Google Play and
          the Google Play logo are trademarks of Google LLC.
        </Typography>
      )}
    </Stack>
  );
}
