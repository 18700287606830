let getCommonStyles = (theme) => {
  return {
    helloWorldStyle: {
      marginTop: "200px",
      textAlign: "center",
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "17.5px",
      letterSpacing: "0.25px",
      color: theme.textColour.primaryColor,
    },
    rootGrid: {
      minHeight: "100vh",
      minWidth: "300px",
      display: "grid",
      gridTemplateRows: "auto 1fr auto",
      gridTemplateColumns: "100%",
    },
    layoutContainer: {
      overflowX: "auto",
      width: "100%",
      margin: "0 auto",
      padding: { xs: "0 10px 40px 10px", md: "0 20px 70px 20px" },
    },
  };
};

export { getCommonStyles };
