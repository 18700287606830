import { createTheme } from "@mui/material";
const theme = createTheme({
  textColour: {
    primaryColor: "#00446B",
  },
  btnColour: {
    btnBackgroundColour: "#00446B",
    btnTextColour: "#fff",
    btnSecondaryBackground: "rgba(105, 87, 178, 0.08)",
  },
  custom: {
    buttonDisabledBg: "rgba(243, 244, 245, 1)",
    activeNavLink: "#00446B",
  },
  palette: {
    accent3: {
      main: "#382D82",
      background: "rgba(105, 87, 178, 0.08)",
    },
  },
});
export default theme;
