import React, { useContext, useEffect, useState } from "react";
import { CircularProgress, Typography, useTheme, Box } from "@mui/material";
import Button from "../../../components/customcomponents/CustomButton";
import GridContainer from "../../../components/grid/GridContainer.jsx";
import GridItem from "../../../components/grid/GridItem.jsx";
import { Helmet } from "react-helmet";
import MuiThemeDataTable from "../../../components/MuiThemeDataTable";
import { getDashboardStyle } from "../../../components/customstyles/DashboardStyle";
import { AuthContext } from "../../../context/AuthContext";
import { getLoginType, getSubType } from "../../public/CommonFunction";
import SuccessDialog from "../../../components/SuccessDialog";
import ActionButton from "./ActionButton";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
const useStyles = () => {
  const theme = useTheme();
  return getDashboardStyle(theme);
};

function AuthenticationConfigurationDashboard() {
  const styles = useStyles();
  const [configurationData, setConfigurationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { makeAuthenticatedApiCall } = useContext(AuthContext);
  const [openSuccessDialog, setSuccessDialog] = useState(false);
  const [bodyText, setBodyText] = useState("");
  const [headerText, setHeaderText] = useState("");
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [authConfigId, setAuthConfigId] = useState("");

  const history = useHistory();

  useEffect(() => {
    const getPatientsList = async () => {
      let response = await makeAuthenticatedApiCall(
        "get",
        "/api/superadmin/authconfiguration",
        null
      );
      if (response.status === 200) {
        if (response.data.length > 0) {
          response.data.forEach((item) => {
            console.log(item);
            item.domainNames = item.domainNames.toString();
            item.loginType = getLoginType(item.loginType, false);
            item.subType = getSubType(item.subType, false);
            item.Action = {
              authConfigid: item.guid,
            };
          });
          setConfigurationData(response.data);
        }
        setLoading(false);
      }
    };
    getPatientsList();
  }, []);

  const handleDeleteAuthConfig = (configId) => {
    setAuthConfigId(configId);
    setConfirmDialog(true);
    setHeaderText("Confirm");
    setBodyText(
      "Are you sure you want to delete this Authentication Configuration?"
    );
  };
  const deleteAuthCondigration = async () => {
    let deleteResponse = await makeAuthenticatedApiCall(
      "delete",
      `/api/superadmin/authconfiguration/${authConfigId}`
    );
    if (deleteResponse.status === 200) {
      setHeaderText("Success");
      setBodyText(deleteResponse.data.statusDescription);
    } else {
      setBodyText("Unable to delete the record.");
      setHeaderText("Error");
    }
    setSuccessDialog(true);
    setConfirmDialog(false);
  };
  const dismissSuccessDialog = () => {
    setSuccessDialog(false);
    let parsed = {};
    parsed.reloadTo = "authenticationconfiguration";
    parsed.timeOut = "100";
    const stringified = queryString.stringify(parsed);
    history.push({
      pathname: `./formReloader`,
      search: "?" + stringified,
    });
  };
  const dismissConfirmDialog = () => {
    setConfirmDialog(false);
  };
  const handleEditAuthConfig = (configId) => {
    history.push("./editaccountconfiguration/" + configId, 0);
  };

  const tablecolumns = [
    {
      name: "domainNames",
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customHeadLabelRender: () => {
          return (
            <Typography sx={styles.tableLabelColor}>Domain Name</Typography>
          );
        },
        customBodyRender: (value) => {
          return (
            <>
              <Typography sx={styles.TableBodySubText}>{value}</Typography>
            </>
          );
        },
      },
    },
    {
      name: "loginType",
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customHeadLabelRender: () => {
          return (
            <Typography sx={styles.tableLabelColor}>Login Type</Typography>
          );
        },
        customBodyRender: (value) => {
          return (
            <>
              <Typography sx={styles.TableBodySubText}>{value}</Typography>
            </>
          );
        },
      },
    },
    {
      name: "subType",
      options: {
        filter: false,
        sort: true,
        searchable: false,
        customHeadLabelRender: () => {
          return <Typography sx={styles.tableLabelColor}>Sub Type</Typography>;
        },
        customBodyRender: (value) => {
          return <Typography sx={styles.TableBodyText}>{value}</Typography>;
        },
      },
    },

    {
      name: "Action",
      label: <Typography sx={styles.tableLabelColor}>Action</Typography>,
      options: {
        filter: false,
        sort: false,
        print: false,
        customBodyRender: (value) => {
          return (
            <ActionButton
              authConfigid={value.authConfigid}
              handleEditAuthConfig={handleEditAuthConfig}
              handleDeleteAuthConfig={handleDeleteAuthConfig}
            />
          );
        },
      },
    },
  ];
  let confirmDialogButton = [
    <Button secondary dialog onClick={dismissConfirmDialog}>
      Cancel
    </Button>,
    <Button primary dialog onClick={deleteAuthCondigration}>
      Delete
    </Button>,
  ];
  let successDialogButton = [
    <Button primary dialog onClick={dismissSuccessDialog}>
      Ok
    </Button>,
  ];

  return (
    <Box sx={styles.tableStyle}>
      <Helmet>
        <title>Authentication Configuration</title>
      </Helmet>
      <GridContainer>
        <GridItem lg={4} md={4} sm={12} xs={12} sx={styles.gridAlign}>
          <p style={styles.headingStyle}>Authentication Configuration</p>
        </GridItem>
        <GridItem
          lg={8}
          md={8}
          sm={12}
          xs={12}
          sx={{
            ...styles.gridAlign,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Button
            primary
            dashboard
            variant="contained"
            sx={styles.addPatientButton}
            onClick={() => {
              history.push("./addauthenticationconfiguration");
            }}
          >
            Add Configuration
          </Button>
        </GridItem>
      </GridContainer>
      {loading ? (
        <Box sx={styles.loadingLeftStyle}>
          <Box sx={{ marginBottom: "20px" }}>
            <CircularProgress />
          </Box>
          <Typography sx={styles.loadinText}>
            Loading Authentication Configuration details...
          </Typography>
        </Box>
      ) : (
        <GridContainer>
          <GridItem md={12}>
            <MuiThemeDataTable
              rows={configurationData}
              columns={tablecolumns}
              hideDownloadIcon={false}
              hideSearchIcon={false}
              hideFilterIcon={false}
              viewColumnsIcon={false}
              smallScreenTableColumnDisplayIndex={[0, 1]}
              sortOrder={{
                name: "appointment",
                direction: "asc",
              }}
            />
          </GridItem>
        </GridContainer>
      )}
      {confirmDialog && (
        <SuccessDialog
          successButton={confirmDialogButton}
          HeaderText={headerText}
          BodyText={bodyText}
          dismiss={dismissConfirmDialog}
        />
      )}
      {openSuccessDialog && (
        <SuccessDialog
          successButton={successDialogButton}
          HeaderText={headerText}
          BodyText={bodyText}
          dismiss={dismissConfirmDialog}
        />
      )}
    </Box>
  );
}
export default AuthenticationConfigurationDashboard;
