import React, { useContext, useState, useEffect } from "react";
import { useTheme, Stack, Typography, Card } from "@mui/material";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { domainValidationPattern } from "../../public/CommonFunction";
import SuccessDialog from "../../../components/SuccessDialog";
import { Formik, Form } from "formik";
import { string, object, mixed } from "yup";
import Button from "../../../components/customcomponents/CustomButton";
import GridContainer from "../../../components/grid/GridContainer.jsx";
import CreateAccountConfigurationUI from "./CreateAccountConfigurationUI";
import EditAuthenticationConfigurationUI from "./EditAuthenticationConfigurationUI";
const useStyles = () => {
  const theme = useTheme();
  return {
    buttonsWrapper: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      marginTop: 10,
    },
    submitMediBtn: {
      minWidth: "242px !important",
      borderRadius: "25px",
      padding: "5px 25px",
      textTransform: "uppercase",
    },
    cancelMediBtn: {
      borderRadius: "25px",
      minWidth: "242px !important",
      padding: "5px 25px",
      marginRight: "10px",
      textTransform: "uppercase",
      "&:hover": {
        backgroundColor: theme.palette.text.primaryButtonTextColor,
        color: theme.palette.primary.main,
        border: "1px solid" + theme.palette.primary.main,
        opacity: 0.8,
      },
    },
    card: {
      padding: "40px",
      borderRadius: "10px",
      marginTop: "3%",
      width: { xs: "100%", sm: "600px", md: "900px", lg: "1100px" },
    },
    subHeader: { padding: "20px 15px", fontSize: "30px !important" },
  };
};

function CreateAuthenticationConfiguration(props) {
  const styles = useStyles();
  const history = useHistory();
  const initialValues = {
    domainName: "",
    loginType: "",
    subType: "",
    iDPRedirectURL: "",
    iDPIssuer: "",
    cert: "",
    isPlusBasedEmailSupported: "",
  };
  const requiredMessage = "This field is required.";
  const { makeAuthenticatedApiCall } = useContext(AuthContext);
  const authconfigurationId = props.match.params.authconfigurationId;
  const [isSuccess, setIsSuccess] = useState(false);
  const [bodyText, setBodyText] = useState("");
  const [headerText, setHeaderText] = useState("");
  const [editAuthConfigData, setEditData] = useState(null);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);

  const yupSchema = object().shape({
    domainName: string()
      .matches(
        domainValidationPattern,
        "This field accepts only comma separated value and valid Domain Name."
      )
      .required(requiredMessage),
    loginType: mixed().required(requiredMessage),
    subType: mixed().when(["loginType"], (loginType) => {
      if (loginType[0] !== undefined && loginType[0].value === 1) {
        return mixed().required(requiredMessage);
      } else {
        return mixed();
      }
    }),
    iDPRedirectURL: mixed().when(["loginType"], (loginType) => {
      if (loginType[0] !== undefined && loginType[0].value === 2) {
        return string().required(requiredMessage);
      } else {
        return string();
      }
    }),
    iDPIssuer: mixed().when(["loginType"], (loginType) => {
      if (loginType[0] !== undefined && loginType[0].value === 2) {
        return string().required(requiredMessage);
      } else {
        return string();
      }
    }),
    cert: mixed().when(["loginType"], (loginType) => {
      if (loginType[0] !== undefined && loginType[0].value === 2) {
        return string().required(requiredMessage);
      } else {
        return string();
      }
    }),
  });
  useEffect(() => {
    if (authconfigurationId) {
      const getEditData = async () => {
        let response = await makeAuthenticatedApiCall(
          "get",
          `/api/superadmin/authconfiguration/${authconfigurationId}`
        );
        if (response.status === 200) {
          setEditData(response.data);
        } else {
          setIsError(true);
        }
      };
      getEditData();
    }
  }, []);
  const handleSubmit = async (values) => {
    setLoading(true);
    let response;
    let DomainName =
      typeof values.domainName !== string
        ? values.domainName.toString()
        : values.domainName;
    let dataToSend = {
      domainNames: DomainName.split(","),
      loginType: values.loginType.value,
      subType: values.loginType.value === 1 && values.subType.value,
      additionalConfiguration: {
        IDPRedirectURL: values.loginType.value === 2 && values.iDPRedirectURL,
        IDPIssuer: values.loginType.value === 2 && values.iDPIssuer,
        cert: values.loginType.value === 2 && values.cert,
        isPlusBasedEmailSpecialProcessing:
          values.loginType.value === 2 &&
          values.isPlusBasedEmailSupported === true
            ? true
            : false,
      },
    };

    if (authconfigurationId) {
      response = await makeAuthenticatedApiCall(
        "post",
        `/api/superadmin/authconfiguration/${authconfigurationId}`,
        dataToSend
      );
    } else {
      response = await makeAuthenticatedApiCall(
        "post",
        "/api/superadmin/authconfiguration",
        dataToSend
      );
    }
    if (response.status === 200) {
      setLoading(false);
      setIsSuccess(true);
      setBodyText(response.data.statusDescription);
      setHeaderText("Success");
      // setUpdateAccountDialog(true);
    } else {
      setBodyText(response.data.statusDescription);
      setLoading(false);
      setIsError(true);
      setHeaderText("Error");
    }
  };

  const updateDismissDialog = () => {
    setIsSuccess(false);
    if (authconfigurationId) {
      history.push("../authenticationconfiguration");
    } else {
      history.push("./authenticationconfiguration");
    }
  };
  let successButton = [
    <Button primary dialog onClick={updateDismissDialog}>
      Ok
    </Button>,
  ];

  return (
    <>
      <Stack alignItems="center" justifyContent="center">
        <Card raised={true} sx={styles.card}>
          <Typography type="body2" sx={styles.subHeader}>
            <b>
              {editAuthConfigData ? "Edit " : "Add "}Authentication
              Configuration
            </b>
          </Typography>
          <>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={yupSchema}
            >
              {(props) => (
                <Form>
                  <GridContainer>
                    {editAuthConfigData && (
                      <EditAuthenticationConfigurationUI
                        editAuthConfigData={editAuthConfigData}
                      />
                    )}
                    <CreateAccountConfigurationUI />
                  </GridContainer>
                  <GridContainer sx={styles.buttonsWrapper}>
                    <Button
                      secondary
                      onClick={updateDismissDialog}
                      sx={styles.cancelMediBtn}
                    >
                      Cancel
                    </Button>{" "}
                    <Button
                      type="submit"
                      primary
                      loading={loading}
                      sx={styles.submitMediBtn}
                    >
                      Save Changes
                    </Button>
                  </GridContainer>
                </Form>
              )}
            </Formik>
          </>
        </Card>
      </Stack>
      {(isSuccess || isError) && (
        <SuccessDialog
          successButton={successButton}
          HeaderText={headerText}
          BodyText={bodyText}
          dismiss={updateDismissDialog}
        />
      )}
    </>
  );
}
export default CreateAuthenticationConfiguration;
