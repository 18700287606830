import React, { useEffect, useState, useRef, useContext } from "react";
import {
  useTheme,
  Card,
  Typography,
  Box,
  Stack,
  Checkbox,
  FormControlLabel,
  Link,
  CircularProgress,
} from "@mui/material";
import { createUserObject } from "./CommonFunction";
import axios from "axios";
import { useHistory } from "react-router-dom";
import FormikTextField from "../../components/formikcomponent/TextField";
import { Helmet } from "react-helmet";
import { Formik, Field, Form } from "formik";
import { number, object } from "yup";
import Button from "../../components/customcomponents/CustomButton";
import GridContainer from "../../components/grid/GridContainer.jsx";
import GridItem from "../../components/grid/GridItem.jsx";
import { AuthContext } from "../../context/AuthContext";
import { LoginPageStyle } from "../../components/customstyles/LoginPageStyle";
import radioButtonChecked from "../../assets/images/radioButtonChecked.svg";
import radioButtonUnchecked from "../../assets/images/radioButtonUnchecked.svg";

const useStyles = () => {
  const theme = useTheme();
  return LoginPageStyle(theme);
};
const OTP_COUNTER_TIMEOUT_IN_SECONDS = 60;

function OTP() {
  const styles = useStyles();
  const history = useHistory();
  const resendOTPCouterIntervalRef = useRef();
  const { changeUser } = useContext(AuthContext);
  const [isLoginCompleted, setLoginCompleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [resendOTPCounter, setResendOTPCounter] = useState(
    OTP_COUNTER_TIMEOUT_IN_SECONDS
  );
  const yupSchema = object({
    otp: number()
      .typeError("One-time password must be a number.")
      .required("This Field is required."),
  });
  useEffect(() => {
    restartResendOTPCounter();
  }, []);
  const handleSubmit = async (values) => {
    setLoginCompleted(true);
    let offset = new Date().getTimezoneOffset() * -1;
    try {
      let response = await axios.post("/api/authservice/login", {
        otp: values.otp,
        offset: offset,
        type: 2,
        rememberMe,
        token: localStorage.getItem("otpToken"),
      });
      if (response.status === 200) {
        if (response.data.status === 1) {
          localStorage.setItem("refreshToken", response.data.refreshToken);
          localStorage.setItem("accessToken", response.data.accessToken);
          changeUser(createUserObject(response.data));
          setLoginCompleted(false);
          history.push("/superadmin");
        }
      } else {
        setLoginCompleted(false);
      }
    } catch (err) {
      if (
        err.response.data.warningmessage ===
        "Your account has been locked. Please contact your admin to unlock or reset your password."
      ) {
      }
      let errMessage = <>{err.response.data.warningmessage}</>;
      setLoginCompleted(false);
      setErrorMessage(errMessage);
    }
  };

  const handleChange = (e) => {
    if (e.target.checked === true) setRememberMe(true);
    if (e.target.checked === false) setRememberMe(false);
  };
  const restartResendOTPCounter = () => {
    if (resendOTPCouterIntervalRef.current) {
      clearInterval(resendOTPCouterIntervalRef.current);
    }
    setResendOTPCounter(OTP_COUNTER_TIMEOUT_IN_SECONDS);
    resendOTPCouterIntervalRef.current = setInterval(() => {
      setResendOTPCounter((prevCounter) => {
        if (prevCounter === 0) {
          clearInterval(resendOTPCouterIntervalRef.current);
          resendOTPCouterIntervalRef.current = null;
          return 0;
        } else {
          return prevCounter - 1;
        }
      });
    }, 1000);
  };

  const getOtpFromAPI = async () => {
    setLoginCompleted(true);
    try {
      const response = await axios({
        method: "post",
        url: "/api/authservice/sendotp",
        data: { emailid: localStorage.getItem("emailId") },
      });

      if (response.status === 200 && response.data.token) {
        restartResendOTPCounter();
        localStorage.setItem("otpToken", response.data.token);
        restartResendOTPCounter();
      }
    } catch (err) {
      setErrorMessage(err.response.data.statusDescription);
    }
    setLoginCompleted(false);
  };
  const handleErrorMessage = () => {
    setErrorMessage("");
  };
  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Stack alignItems="center" justifyContent="center" sx={styles.container}>
        <Card raised={true} sx={styles.card}>
          <Box sx={styles.formLaylout}>
            <Typography sx={styles.headingtext}>Login</Typography>
            <Formik
              initialValues={{ emailId: "", otp: "" }}
              onSubmit={handleSubmit}
              validationSchema={yupSchema}
            >
              {(props) => (
                <Form>
                  <GridContainer>
                    <GridItem>
                      <Typography sx={styles.LoginSubHeading} type="body1">
                        A one-time password has been emailed to you (if you
                        don’t see it in your inbox, be sure to check your spam
                        folder as well). Enter your one-time password below.
                      </Typography>
                      <br />
                      <br />
                      <Field
                        component={FormikTextField}
                        value={props.values.otp || ""}
                        variant="filled"
                        label="One-Time Password (OTP)"
                        fullWidth
                        name="otp"
                        onChange={handleErrorMessage}
                        placeholder="Enter OTP"
                        InputProps={{
                          autoComplete: "off",
                        }}
                        readOnly
                      />
                      {isLoginCompleted && (
                        <CircularProgress
                          color="primary"
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "45%",
                          }}
                        />
                      )}
                      <Box style={{ textAlign: "left" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              icon={<img src={radioButtonUnchecked} />}
                              checkedIcon={<img src={radioButtonChecked} />}
                              onChange={handleChange}
                            />
                          }
                          onChange={handleChange}
                          label={
                            <Typography
                              type="body1"
                              component="label"
                              sx={styles.fifteen}
                            >
                              Trust this computer
                            </Typography>
                          }
                        />
                        <br />
                        {resendOTPCounter === 0 ? (
                          <Link
                            color="#2196F3"
                            sx={{
                              cursor: "pointer",
                              width: "auto",
                              textDecoration: "none",
                            }}
                            underline="hover"
                            onClick={async () => {
                              await getOtpFromAPI();
                            }}
                          >
                            <Typography sx={styles.resendText}>
                              Re-send email.
                            </Typography>
                          </Link>
                        ) : (
                          <Typography sx={styles.sendOtpText}>
                            You can re-send an email with your OTP in{" "}
                            {resendOTPCounter} second(s)
                          </Typography>
                        )}
                      </Box>
                      <p style={{ color: "#d51035" }}>{errorMessage}</p>
                      <br />
                      <Button
                        type="submit"
                        primary
                        dashboard
                        size="small"
                        disabled={isLoginCompleted}
                      >
                        Next
                      </Button>
                    </GridItem>
                  </GridContainer>
                </Form>
              )}
            </Formik>
          </Box>
        </Card>
      </Stack>
    </>
  );
}
export default OTP;
