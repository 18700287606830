import React from "react";
import { Box, Link } from "@mui/material";
import elevmi_short from "../../assets/images/elevmi_short.svg";

export function Footer() {
  return (
    <Box>
      <Box
        component={"footer"}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          background: "linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF",
          boxShadow: {
            sm: "0px 0px 5px 0px rgba(0, 0, 0, 0.04), 0px 0px 10px 0px rgba(0, 0, 0, 0.02)",
          },
          display: "flex",
          width: "100%",
          whiteSpace: "nowrap",
          padding: { xs: "34px 20px", lg: "60px 60px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: { xs: "16px", md: "18px" },
            width: "333px",
            maxWidth: "100%",
            flexDirection: "column",
          }}
        >
          <img
            loading="lazy"
            src={elevmi_short}
            alt="Elevmi app for caregivers icon"
            style={{
              objectFit: "auto",
              objectPosition: "center",
              width: "80px",
              alignSelf: "center",
            }}
          />
          <Box
            sx={{
              fontFamily: "Open Sans, sans-serif",
              fontWeight: "600",
              fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "24px" },
              lineHeight: { xs: "21.79px", lg: "27.24px" },
              textAlign: "center",
            }}
          >
            You’re doing an amazing job.
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          borderTop: "1px solid #EDE9FD",
          backgroundColor: "#FFF",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          width: "100%",
          gap: "20px",
          padding: { xs: "24px 16px", md: "24px 80px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            gap: { xs: "16px", lg: "24px" },
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: { xs: "16px", lg: "24px" },
            }}
          >
            <Link
              href={"/api/V1/resourceDetails/privacyPolicy/3"}
              about="Go to Elevmi Privacy Policy"
              target="_blank"
              sx={{
                textDecoration: "none",
                color: "#6A48B9",
                whiteSpace: "nowrap",
                fontFamily: "Open Sans, sans-serif",
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: { xs: "19.07px", md: "21.79px" },
              }}
            >
              Privacy Policy
            </Link>{" "}
            <Link
              href={"/api/V1/resourceDetails/termsAndCondition/3"}
              about="Go to Elevmi Terms of Use"
              target="_blank"
              sx={{
                textDecoration: "none",
                color: "#6A48B9",
                whiteSpace: "nowrap",
                fontFamily: "Open Sans, sans-serif",
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: { xs: "19.07px", md: "21.79px" },
              }}
            >
              Terms of Use
            </Link>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              borderRadius: "40px",
              backgroundColor: "#C4B7F8",
              width: "1px",
              height: "57px",
            }}
          />{" "}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", md: "flex-start" },
            }}
          >
            <Box
              sx={{
                color: "#312A4E",
                flexGrow: "1",
                font: "400 14px Open Sans, sans-serif ",
                textAlign: { xs: "center", md: "left" },
              }}
            >
              © 2024 Otsuka Precision Health. All rights reserved. Elevmi™ is
              not specific to any prescription treatment. Elevmi™ is a
              registered trademark of Otsuka Precision Health, Inc.
            </Box>
          </Box>
        </Box>{" "}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            color: "#312A4E",
            textAlign: { xs: "center", sm: "right" },
            font: "400 14px Open Sans, sans-serif ",
            gap: { xs: "15px", md: "25px", lg: "50px" },
          }}
        >
          <Box sx={{ whiteSpace: "nowrap" }}>July 2024</Box>
          <Box sx={{ whiteSpace: "nowrap" }}>24US24EUC0081</Box>
        </Box>
      </Box>
    </Box>
  );
}
