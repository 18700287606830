import React from "react";
import { Box } from "@mui/material";
import comma from "../../assets/images/comma.svg";

function SharingCard(props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box
        sx={{
          borderRadius: "20px",
          backgroundColor: "rgba(196, 183, 248, 0.30)",
          display: "flex",
          flexGrow: "1",
          flexDirection: "column",
          width: "100%",
          padding: { xs: "48px 30px", md: "49px 30px" },
        }}
      >
        <Box
          sx={{
            aspectRatio: "1.3",
            objectFit: "auto",
            objectPosition: "center",
            width: "39px",
            fill: "#ECB4C9",
          }}
        >
          <img loading="lazy" src={comma} alt="" sx={{ width: "38.5px" }} />
        </Box>
        <Box
          sx={{
            color: "#FBFAFF",
            marginTop: "34px",
            fontFamily: "Open Sans, sans-serif ",
            fontSize: { xs: "18px", lg: "20px", xl: "22px" },
            lineHeight: { xs: "24.51px", lg: "27.24px", xl: "29.96px" },
            fontWeight: "600",
            letterSpacing: "-1px",
          }}
        >
          “{props.text}”
        </Box>
        <Box
          sx={{
            color: "#FFF",
            marginTop: "34px",
            fontFamily: "400 14px Open Sans, sans-serif ",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "19.07px",
          }}
        >
          {props.caregiver}
        </Box>
      </Box>
    </Box>
  );
}

export function SharingCards() {
  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        background: "linear-gradient(295deg, #24194D 0.39%, #594995 77.58%)",
        display: "flex",
        width: "100%",
        padding: { xs: "48px 16px", md: "100px 60px 80px 60px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          maxWidth: "1256px",
          flexDirection: "column",
          gap: "60px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              color: "#FBFAFF",
              letterSpacing: "-1px",
              fontFamily: "Open Sans, sans-serif",
              fontWeight: "600",
              fontSize: { xs: "28px", sm: "26px", md: "30px", lg: "40px" },
              lineHeight: { xs: "38.13px", md: "40.85px", lg: "54.47px" },
            }}
          >
            Caregivers share
          </Box>
          <Box
            sx={{
              color: "#FBFAFF",
              maxWidth: {
                xs: "initial",
                sm: "414px",
                md: "552px",
                xl: "616px",
              },
              fontFamily: "Open Sans, sans-serif",
              fontWeight: "400",
              fontSize: { xs: "16px", lg: "18px" },
              lineHeight: { xs: "21.79px", xl: "24.51px" },
              letterSpacing: "-1px",
            }}
          >
            Our committee of caregivers helped us create an app that would be
            most useful for them. They also graciously offered heartfelt advice
            for fellow caregivers like you.
          </Box>
        </Box>
        <Box
          sx={{
            justifyContent: "space-between",
            alignContent: "flex-start",
            gap: "20px",
            alignSelf: "stretch",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "stretch",
              gap: { xs: "16px", md: "20px" },
            }}
          >
            <SharingCard
              text={
                "You need a support system. You need to take care of yourself. It’s a long difficult journey at times."
              }
              caregiver={"Deanna, Primary Caregiver"}
            />
            <SharingCard
              text={
                "Caregiving can be exhausting, look after your health because it is only in good health that you can provide the necessary care for your loved one needs."
              }
              caregiver={"Deborah, Shared Caregiver"}
            />
            <SharingCard
              text={
                "Stay informed, be patient and do not be afraid to ask for help."
              }
              caregiver={"Esteban, Primary Caregiver"}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
