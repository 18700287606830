import React from "react";
import { Footer } from "../../../components/home/Footer";
import { NavBar } from "../../../components/home/NavBar";
import { Box } from "@mui/material";

function Base({ children }) {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "grid",
        gridTemplateRows: "auto 1fr auto",
        gridTemplateColumns: "100%",
        background: "#FFF",
      }}
    >
      <NavBar />
      <Box
        sx={{
          overflowX: "auto",
          width: "100%",
          padding: { xs: "40px 16px", sm: "64px 24px" },
        }}
      >
        {children}
      </Box>
      <Footer />
    </Box>
  );
}

export default Base;
