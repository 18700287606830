import React from "react";
import { useTheme, Link, Typography, Box } from "@mui/material";
import logoutImage from "../../assets/images/logout.svg";
import { useHistory } from "react-router-dom";
import { getPageNotFoundStyle } from "../../components/customstyles/PageNotFoundStyle";
const useStyles = () => {
  const theme = useTheme();
  return getPageNotFoundStyle(theme);
};

function ThankYouPage() {
  const styles = useStyles();
  const history = useHistory();

  return (
    <Box sx={styles.logoutPageStyle}>
      <img alt="logout icon" src={logoutImage} style={styles.logoutIcon} />
      <Typography sx={styles.headingText}>
        You have successfully logged out.
      </Typography>
      <Typography sx={styles.subHeadingText}>
        If you need to access OLYMPIA again, Please click on login button,
        <br />
        And enter the username and password to login.
      </Typography>
      <Link
        sx={styles.backLinkStyle}
        onClick={() => {
          localStorage.removeItem("otpToken");
          history.push(`./login`);
        }}
      >
        Back To Login
      </Link>
    </Box>
  );
}
export default ThankYouPage;
