import React from "react";
import { useTheme } from "@mui/material";
import FormikTextField from "../../../components/formikcomponent/TextField";
import FormikSelectWithLabel from "../../../components/formikcomponent/SelectFieldWithLabel";
import SimpleCheckBox from "../../../components/formikcomponent/SimpleCheckBox";
import GridContainer from "../../../components/grid/GridContainer.jsx";
import GridItem from "../../../components/grid/GridItem.jsx";
import { connect, Field } from "formik";
import { loginOptions, subTypeOptions } from "../../public/CommonFunction";
import { AuthenticaitionConfigurationUI } from "../../../components/customstyles/AuthenticaitionConfigurationStyle";
const useStyles = () => {
  const theme = useTheme();
  return AuthenticaitionConfigurationUI(theme);
};
const CreateAccountConfigurationUI = (props) => {
  const styles = useStyles();
  return (
    <>
      <GridContainer>
        <GridItem lg={6} md={6} sm={12} xs={12} sx={styles.formikFieldStyle}>
          <Field
            component={FormikTextField}
            variant="filled"
            label="Domain Name"
            fullWidth
            name="domainName"
            placeholder="Domain Name"
          />
        </GridItem>
        <GridItem
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={styles.formikFieldStyle}
          className="SelectWithLabel"
        >
          <Field
            name="loginType"
            label="Login Type"
            placeholder={""}
            options={loginOptions}
            component={FormikSelectWithLabel}
            className={"selectstyle"}
            isSearchable={false}
            variant="filled"
            fullWidth
            menuPortalTarget={document.body}
            InputProps={{
              styles: {
                backgroundColor: "#fff",
                minHeight: "47px",
                fontSize: "14px",
                width: "100% !important",
              },
            }}
          />
        </GridItem>
        {props.formik.values.loginType &&
          props.formik.values.loginType.value === 1 && (
            <GridItem
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={styles.formikFieldStyle}
              className="SelectWithLabel"
            >
              <Field
                name="subType"
                label="Sub Type"
                placeholder={""}
                options={subTypeOptions}
                component={FormikSelectWithLabel}
                isSearchable={false}
                variant="filled"
                menuPortalTarget={document.body}
              />
            </GridItem>
          )}
        {props.formik.values.loginType &&
          props.formik.values.loginType.value === 2 && (
            <>
              <GridItem
                lg={6}
                md={6}
                sm={12}
                xs={12}
                sx={styles.formikFieldStyle}
              >
                <Field
                  name="iDPRedirectURL"
                  label="IDP Redirect URL"
                  variant="filled"
                  fullWidth
                  component={FormikTextField}
                />
              </GridItem>
              <GridItem
                lg={6}
                md={6}
                sm={12}
                xs={12}
                sx={styles.formikFieldStyle}
              >
                <Field
                  name="iDPIssuer"
                  label="IDP Issuer"
                  variant="filled"
                  fullWidth
                  component={FormikTextField}
                />
              </GridItem>
              <GridItem
                lg={6}
                md={6}
                sm={12}
                xs={12}
                sx={styles.formikFieldStyle}
              >
                <Field
                  component={SimpleCheckBox}
                  name="isPlusBasedEmailSupported"
                  label="Plus Based Email Supported"
                  checked={
                    props.formik.values.isPlusBasedEmailSupported
                      ? props.formik.values.isPlusBasedEmailSupported
                      : false
                  }
                />
              </GridItem>
              <GridItem
                lg={10}
                md={10}
                sm={12}
                xs={12}
                sx={styles.formikFieldStyle}
              >
                <br />
                <Field
                  name="cert"
                  label="Certificate"
                  variant="filled"
                  fullWidth
                  component={FormikTextField}
                  InputProps={{ multiline: true, rows: 10 }}
                />
              </GridItem>
            </>
          )}
      </GridContainer>
    </>
  );
};

export default connect(CreateAccountConfigurationUI);
