import React, { useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";

function ProcessSSO(props) {
  const history = useHistory();
  const fetchRefreshToken = async () => {
    try {
      let refreshToken = await axios.post(
        `/api/authservice/${props.location.pathname}`,
        null
      );
      if (refreshToken.status == 200) {
        localStorage.setItem("refreshToken", refreshToken.data.refreshToken);
        history.push(`/superadmin`);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status == 422) {
          history.push(
            `/errorpage?errorMessage=${error.response.data.error.details}`
          );
        } else {
          history.push(
            `/errorpage?errorMessage=${error.response.data.statusDescription}`
          );
        }
      } else {
        history.push(`/errorpage?errorMessage=${error}`);
      }
    }
  };
  useEffect(() => {
    fetchRefreshToken();
  });
  return (
    <div>
      <CircularProgress
        color="primary"
        style={{ position: "absolute", top: "50%", left: "50%" }}
      />
    </div>
  );
}
export default ProcessSSO;
