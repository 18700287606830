const getDashboardObject = (theme) => {
  return {
    navBarIconStyle: {
      width: "18px",
      marginRight: "8px",
    },
    addPatientButton: {
      padding: "10px 22px",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "22px",
      letterSpacing: "1px",
      backgroundColor: theme.textColour.primaryColor,
    },
    tableStyle: {
      maxWidth: "1300px",
      width: "100%",
      margin: "0px auto",
      padding: "0 20px",
    },
    tableLabelColor: {
      textTransform: "capitalize",
      margin: 0,
      color: theme.textColour.headingColour,
      fontSize: "14px",
      lineHeight: "22.2px",
      letterSpacing: ".1px",
      fontWeight: "bolder",
    },
    TableBodySubText: {
      color: theme.textColour.headingColour,
      fontSize: "14px",
      lineHeight: "17.5px",
      letterSpacing: "0.25px",
    },
    loadinText: {
      fontSize: "14px",
      color: theme.textColour.textTertiary,
      fontWeight: 400,
      lineHeight: "22px",
      letterSpacing: ".5px",
    },
    headingStyle: {
      fontSize: "24px",
      fontWeight: 500,
      letterSpacing: "1px",
      lineHeight: "32px",
      color: theme.textColour.headingColour,
      margin: "20px 0",
    },
    loadingLeftStyle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      transform: "translate(-50%, -50%)",
    },
    gridAlign: {
      alignItems: { xs: "left", sm: "center" },
      margin: "20px 0 15px",
    },
    headingStyle: {
      fontSize: "24px",
      color: theme.textColour.headingColour,
      fontWeight: 600,
      lineHeight: "32px",
      letterSpacing: "1px",
    },
    subHeadingStyle: {
      fontSize: "14px",
      color: theme.palette.text.textSecondaryColor,
      fontWeight: 400,
      lineHeight: "22px",
      letterSpacing: "0.5px",
    },

    navbarLogoutStyle: {
      color: theme.custom.activeNavLink,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      cursor: "pointer",
      padding: "20px 10px",
      marginRight: "10px",
      float: "right",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: 1,
      [theme.breakpoints.down("xs")]: { float: "left", padding: "0px" },
    },
    menuButton: {
      padding: "12px",
      marginLeft: { xs: "10px" },
      height: 46,
      color: theme.custom.activeNavLink,
    },
    root: { flexGrow: 1 },
    logoBg: {
      padding: "0px 20px 0px 20px",
      backgroundColor: theme.custom.navbarImageBg,
    },
    logoForMobile: {
      [`& img`]: { width: "150px" },
    },
    dispBlk: {
      [theme.breakpoints.down("sm")]: { display: "block", width: "100%" },
    },
    dialogHeading: {
      fontSize: "18px",
      color: theme.textColour.headingColour,
      lineHeight: "21.09px",
      letterSpacing: "0.15px",
      fontWeight: 600,
    },
    dialogbtnStyle: {
      margin: "5px",
      cursor: "pointer",
      fontSize: "14px",
      color: theme.textColour.primaryColor,
      lineHeight: "24px",
      letterSpacing: "1px",
      fontWeight: 600,
    },
    dialogBodytext: {
      textAlign: "justify",
      fontSize: "14px",
      color: theme.textColour.dialogBodytext,
      lineHeight: "22px",
      letterSpacing: "0.5px",
      fontWeight: 400,
    },
    appBar: {
      // position: "sticky",
      // top: 0,
      // zIndex: 100,
      // borderTop: `7px solid #FFD100`,
      borderBottom: "3px solid #ECEEF0",
      // boxShadow: "none !important",
      backgroundColor: "#fff",
    },
  };
};

export const getDashboardStyle = getDashboardObject;
