import React from "react";
import { useTheme, FormControlLabel, Typography } from "@mui/material";
import Checkbox from "../customcomponents/Checkbox";

const useStyles = () => {
  const theme = useTheme();
  return {
    checkbox: {
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: theme.textColour.primaryColor,
    },
  };
};

export default function ({
  field: { value, name, onChange, onBlur, checked },
  label,
  identifier,
  ...otherProps
}) {
  const styles = useStyles();

  return (
    <>
      <FormControlLabel
        name={name}
        label={
          <Typography variant="h6" sx={styles.checkbox} type="body3">
            {label}
          </Typography>
        }
        control={
          <Checkbox
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
            onBlur={onBlur}
          />
        }
        {...otherProps}
      />
    </>
  );
}
