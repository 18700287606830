import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import RenderAppBasedOnInternet from "./RenderAppBasedOnInternet";
import axios from "axios";
import { Amplify, Analytics } from "aws-amplify";
import {
  awsPinPointConfig,
  sessionConfig,
  pageOptions,
  initialisePinPointConfig,
} from "./pinpointConfig";
import Home from "./views/public/Home";
import Support from "./views/public/static/Support";
import Unsubscribe from "./views/public/static/Unsubscribe";
import { ThemeProvider, createTheme } from "@mui/material";

const root = ReactDOM.createRoot(document.getElementById("root"));

let getConfig = async () => {
  let result = await axios.get("/api/userauthservice/getDefaultConfig");
  initialisePinPointConfig(
    result.data.pinPointDetails.idpId,
    result.data.pinPointDetails.region,
    result.data.pinPointDetails.appId
  );

  Amplify.configure(awsPinPointConfig()["Auth"]);
  Amplify.configure(awsPinPointConfig()["Analytics"]);
  Analytics.autoTrack("session", sessionConfig);
  Analytics.autoTrack("pageView", pageOptions);
  return result.data;
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#6A48B9",
      dark: "#382D82",
      light: "#8B6EB0",
      contrastText: "#fff",
    },
    secondary: {
      main: "#382D82",
    },
  },
});

getConfig().then((config) =>
  root.render(
    <BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Home appDownloadUrl={config.appDownloadUrl} />}
          />
          <Route exact path="/support" component={Support} />
          <Route exact path="/unsubscribe" component={Unsubscribe} />
          <Route path="*" component={RenderAppBasedOnInternet} />
        </Switch>
      </ThemeProvider>
    </BrowserRouter>
  )
);
