exports.loginRedirectUrl = (userrole) => {
  let redirectUrl = "";
  if (userrole === "SuperAdmin" || userrole === 1) {
    redirectUrl = "/superadmin";
  }
  return "/superadmin";
};
exports.domainValidationPattern =
  /^\s*(?:(?:\w+(?:-+\w+)*\.)+[a-zA-Z]+)\s*(?:,\s*(?:(?:\w+(?:-+\w+)*\.)+[a-zA-Z]+)\s*)*$/;

exports.createUserObject = (userData) => {
  return {
    name: "abcd",
    isAuthenticated: true,
    role: userData.userrole,
    permissionlevel: userData.permissionlevel,
    firstname: userData.firstname,
    lastname: userData.lastname,
    userTableId: userData.userTableId,
    userid: userData.userid,
    cellnumber: userData.cellnumber,
    country: userData.country,
  };
};
const loginOptions = [
  { value: 1, label: "Local Authentication" },
  { value: 2, label: "SAML" },
];
const subTypeOptions = [
  { value: 1, label: "Password + OTP" },
  { value: 2, label: "OTP" },
];
exports.getLoginType = (loginType, isObject) => {
  let selectedLoginType;
  loginOptions.forEach((item) => {
    if (item.value === parseInt(loginType)) {
      selectedLoginType = isObject ? item : item.label;
    }
  });
  return selectedLoginType;
};
exports.getSubType = (subType, isObject) => {
  let selectedSubType;
  subTypeOptions.forEach((item) => {
    if (item.value === parseInt(subType)) {
      selectedSubType = isObject ? item : item.label;
    }
  });
  return selectedSubType;
};

exports.loginOptions = loginOptions;
exports.subTypeOptions = subTypeOptions;
