import React from "react";
import { Helmet } from "react-helmet";
import { Formik, Form } from "formik";
import { bool, object, string } from "yup";
import { Footer } from "../../components/home/Footer";
import { NavBar } from "../../components/home/NavBar";
import { SharingCards } from "../../components/home/SharingCards";
import { Hero } from "../../components/home/Hero";
import { CardsSection } from "../../components/home/CardsSection";
import { ElephantSection } from "../../components/home/ElephantSection";

export default function Home(props) {
  return (
    <>
      <Helmet>
        <title>Elevmi</title>
      </Helmet>
      <NavBar />
      <Formik
        initialValues={{ emailid: "", agree: false }}
        validationSchema={object({
          emailid: string()
            .email("Please enter a valid email address.")
            .required("Please enter your email address."),
          agree: bool().isTrue("Please agree to the terms above to continue."),
        })}
        onSubmit={async (values, helper) => {
          helper.setStatus("submiting");
          const minimumWaitTime = 1000;
          const delay = new Promise((r) => setTimeout(r, minimumWaitTime));
          const [, response] = await Promise.all([
            delay,
            fetch("/api/V1/signupforwaitlist", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email: values.emailid,
              }),
            }),
          ]);
          if (response && (response.ok || response.status === 409)) {
            helper.setStatus("submitted");
          } else {
            helper.setFieldError(
              "emailid",
              "Please enter a valid email address."
            );
            helper.setStatus(null);
          }
        }}
      >
        <Form>
          <Hero appDownloadUrl={props.appDownloadUrl} />
          <CardsSection />
          <ElephantSection appDownloadUrl={props.appDownloadUrl} />
          <SharingCards />
          <Footer />
        </Form>
      </Formik>
    </>
  );
}
