import React from "react";
import { Helmet } from "react-helmet";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import SuperAdminDashboard from "./SuperAdminDashboard";
import { useTheme } from "@mui/material";
import FormReloader from "../../components/FormsReloader";
import AuthenticationConfigurationDashboard from "./AuthenticationConfiguration/AuthenticationConfigurationDashboard";
import CreateAuthenticationConfiguration from "./AuthenticationConfiguration/CreateAuthenticationConfiguration";
import Navbar from "../../components/Navbar";
import patientImage from "../../assets/images/patient.svg";
import { getDashboardStyle } from "../../components/customstyles/DashboardStyle";
const useStyles = () => {
  const theme = useTheme();
  return getDashboardStyle(theme);
};

function SuperAdmin() {
  const styles = useStyles();

  const match = useRouteMatch();
  const Nav = [
    // {
    //   to: `${match.url}/dashboard`,
    //   image: patientImage,
    //   title: "Dashboard",
    //   style: styles.navBarIconStyle,
    // },
    {
      to: `${match.url}/authenticationconfiguration`,
      image: patientImage,
      title: "Authentication Configuration",
      style: styles.navBarIconStyle,
    },
  ];
  return (
    <>
      <Helmet>
        <title>Patient</title>
      </Helmet>
      <Navbar Nav={Nav} />

      <Switch>
        <Route
          path={`${match.url}/authenticationconfiguration`}
          component={AuthenticationConfigurationDashboard}
        />
        <Route
          path={`${match.url}/addauthenticationconfiguration`}
          component={CreateAuthenticationConfiguration}
        />
        <Route
          path={`${match.url}/editaccountconfiguration/:authconfigurationId`}
          component={CreateAuthenticationConfiguration}
        />
        <Route
          path={`${match.url}/dashboard`}
          component={SuperAdminDashboard}
        />
        <Route path={`${match.url}/formReloader`} component={FormReloader} />

        <Redirect to={`${match.url}/authenticationconfiguration`}></Redirect>
      </Switch>
    </>
  );
}
export default SuperAdmin;
