import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import LoginPage from "../views/public/LoginPage";
import OTP from "../views/public/OTP";
import { Box, useTheme } from "@mui/material";
import SuperAdmin from "../views/superadmin/SuperAdmin";
import ThankYouPage from "../views/public/ThankYouPage";
import TimeOutBackground from "../components/TimeOutBackground";
import { getCommonStyles } from "../components/customstyles/CommonStyles";
import ProcessSSO from "../views/public/ProcessSSO";
import ErrorPage from "../views/public/ErrorPage";

const useStyles = () => {
  const theme = useTheme();
  return getCommonStyles(theme);
};

function AppLayout(props) {
  const styles = useStyles();
  return (
    <Box sx={styles.rootGrid}>
      <Box sx={styles.layoutContainer}>
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/otp" component={OTP} />
          {props.location.pathname.includes("/superadmin") && <SuperAdmin />}
          <Route path="/processSSO" component={ProcessSSO} />
          <Route path="/errorpage" component={ErrorPage} />
          <Route path="/timeout" component={TimeOutBackground} />
          <Route path="/logout" component={ThankYouPage} />
          <Redirect to={"/login"} />
        </Switch>
      </Box>
    </Box>
  );
}

export default AppLayout;
