import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Button from "../../components/customcomponents/CustomButton";
import queryString from "query-string";
import { ErrorPageStyle } from "../../components/customstyles/LoginPageStyle";
import { useHistory } from "react-router-dom";
import { useTheme, Box, Typography } from "@mui/material";
const useStyles = () => {
  const theme = useTheme();
  return ErrorPageStyle(theme);
};

function ErrorPage(props) {
  const styles = useStyles();
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState(null);
  useEffect(() => {
    let { errorMessage } = queryString.parse(props.location.search);
    setErrorMessage(errorMessage);
  });
  const dismissDialog = () => {
    history.push(`../login`);
  };
  return (
    <>
      <Helmet>
        <title>Error Page</title>
      </Helmet>
      <Box sx={styles.tableStyle}>
        <Typography sx={styles.LoginHeading} type="title">
          Error...{" "}
        </Typography>
        <br />
        <Typography sx={styles.errorText}>{errorMessage}</Typography>
        <div sx={styles.cardFooter} disabled={props.isSubmitting}>
          <br />
          <br />
          <Button primary dashboard onClick={dismissDialog}>
            Login
          </Button>
        </div>
      </Box>
    </>
  );
}

export default ErrorPage;
