import React from "react";
import { connect } from "formik";
import { useEffect } from "react";
import { getLoginType, getSubType } from "../../public/CommonFunction";

const EditAuthenticationConfigurationUI = (props) => {
  useEffect(() => {
    let data = props.editAuthConfigData;
    if (data) {
      props.formik.setFieldValue("domainName", data.domainNames.toString());
      props.formik.setFieldValue(
        "loginType",
        getLoginType(data.loginType, true)
      );
      if (parseInt(data.loginType) === 1) {
        props.formik.setFieldValue("subType", getSubType(data.subType, true));
      }
      if (parseInt(data.loginType) === 2) {
        props.formik.setFieldValue(
          "iDPRedirectURL",
          data.additionalConfiguration.IDPRedirectURL
        );
        props.formik.setFieldValue(
          "iDPIssuer",
          data.additionalConfiguration.IDPIssuer
        );
        props.formik.setFieldValue("cert", data.additionalConfiguration.cert);
        props.formik.setFieldValue(
          "isPlusBasedEmailSupported",
          data.additionalConfiguration.isPlusBasedEmailSpecialProcessing
        );
      }
    }
  }, []);
  return <></>;
};

export default connect(EditAuthenticationConfigurationUI);
