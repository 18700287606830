import React, { useState, useContext } from "react";
import {
  useTheme,
  Card,
  Typography,
  InputAdornment,
  Stack,
  Box,
} from "@mui/material";
import axios from "axios";
import queryString from "query-string";
import { Redirect } from "react-router-dom";
import { createUserObject } from "./CommonFunction";
import FormikTextField from "../../components/formikcomponent/TextField";
import { Helmet } from "react-helmet";
import { Formik, Field, Form } from "formik";
import { string, object } from "yup";
import Button from "../../components/customcomponents/CustomButton";
import GridContainer from "../../components/grid/GridContainer.jsx";
import GridItem from "../../components/grid/GridItem.jsx";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { AuthContext } from "../../context/AuthContext";
import { LoginPageStyle } from "../../components/customstyles/LoginPageStyle";

const useStyles = () => {
  const theme = useTheme();
  return LoginPageStyle(theme);
};

const MAX_CHARS_IN_EMAIL = 100;
function LoginPage(props) {
  const styles = useStyles();
  const { changeUser } = useContext(AuthContext);
  const [showOTP, setShowOtp] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [redirectTo, setRedirectTo] = useState("");
  const [forceRedirect, setForceRedirect] = useState(false);
  const [emailOtpFlow, setEmailOtpFlow] = useState(null);
  const [isLoginCompleted, setLoginCompleted] = useState(false);
  const [displayPasswordScreen, setDisplayPasswordScreen] = useState(false);
  const [renderErrorFromSendotpAPI, setRenderErrorFromSendotpAPI] =
    useState(false);

  const yupSchema = object({
    emailId: string()
      .trim()
      .max(MAX_CHARS_IN_EMAIL, "Email length too long.")
      .required("This Field is required.")
      .email("Please enter a valid email id."),
    password: string().when("displayPasswordScreen", {
      is: () => displayPasswordScreen,
      then: () => string().required("This Field is required."),
    }),
  });

  const hanldeError = (errorData) => {
    setIsError(true);
    if (errorData.response.status === 403) {
      setErrorMessage(errorData.response.data.warningmessage);
    } else {
      setErrorMessage("Something went wrong.");
    }
  };
  const handleSubmit = async (values) => {
    localStorage.setItem("emailId", values.emailId);
    setLoginCompleted(true);
    let offset = new Date().getTimezoneOffset() * -1;
    let { redirectTo } = queryString.parse(props.location.search);
    if (displayPasswordScreen) {
      try {
        let response = await axios.post("/api/authservice/login", {
          username: values.emailId,
          password: values.password,
          offset: offset,
          type: 1,
        });
        if (response.status === 200) {
          setLoginCompleted(false);
          if (response.data.type === 2) {
            localStorage.setItem("otpToken", response.data.token);
            // props.handleTrustComputer(true)
            setEmailOtpFlow(response.data.type);
          } else {
            localStorage.setItem("refreshToken", response.data.refreshToken);
            localStorage.setItem("accessToken", response.data.accessToken);
            changeUser(createUserObject(response.data));
            setForceRedirect(true);
            setRedirectTo("/superadmin");
          }
        }
      } catch (err) {
        setLoginCompleted(false);
        hanldeError(err);
      }
    } else {
      let response = await axios.post("/api/authservice/initiateAuth", {
        emailid: values.emailId,
      });
      if (response.status === 200) {
        setLoginCompleted(false);
        let initialAuthData = response.data;

        if (response.data.type === 2) {
          //SAML authentication flow in progress
          let SAMLRedirectURL = await axios.post(
            "/api/authservice/samlredirecturl",
            {
              emailid: values.emailId,
            }
          );
          window.location = SAMLRedirectURL.data.redirecturl;
        } else {
          if (initialAuthData.variant === 2) {
            setLoginCompleted(false);
            try {
              let sendOTPResponse = await axios.post(
                "/api/authservice/sendotp",
                {
                  emailid: values.emailId,
                }
              );
              if (sendOTPResponse.status == 200) {
                localStorage.setItem("otpToken", sendOTPResponse.data.token);
                setRedirectTo(redirectTo);
                setEmailOtpFlow(response.data.variant);
              }
            } catch (err) {
              setLoginCompleted(false);
              setRenderErrorFromSendotpAPI(true);
              hanldeError(err);
            }
          } else {
            setLoginCompleted(false);
            setDisplayPasswordScreen(true);
          }
        }
      }
    }
  };
  const handleEditEmail = (props) => {
    props.setFieldValue("password", "");
    setErrorMessage("");
    setDisplayPasswordScreen(false);
  };
  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Stack alignItems="center" justifyContent="center" sx={styles.container}>
        <Card raised={true} sx={styles.card}>
          <Box sx={styles.formLaylout}>
            <Typography sx={styles.headingtext}>Login</Typography>
            {forceRedirect ? (
              <Redirect to={redirectTo} />
            ) : (
              <>
                {emailOtpFlow === 2 && <Redirect to={`/otp`} />}
                <Formik
                  initialValues={{ emailId: "", otp: "", password: "" }}
                  onSubmit={handleSubmit}
                  validationSchema={yupSchema}
                >
                  {(props) => (
                    <Form>
                      <GridContainer>
                        <GridItem lg={12} md={12} sm={12} xs={12}>
                          <Field
                            component={FormikTextField}
                            variant="filled"
                            label="Enter Email"
                            fullWidth
                            name="emailId"
                            placeholder="Enter Email"
                            InputProps={{
                              disabled: displayPasswordScreen,
                              endAdornment: displayPasswordScreen && (
                                <InputAdornment position="end">
                                  <EditIcon
                                    onClick={() => handleEditEmail(props)}
                                    sx={styles.inputIconStyle}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </GridItem>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        {displayPasswordScreen && (
                          <GridItem>
                            <Field
                              onChange={() => setIsError(false)}
                              component={FormikTextField}
                              variant="filled"
                              label="Enter Password"
                              id="password"
                              fullWidth
                              name="password"
                              placeholder="Password"
                              autoComplete="off"
                              InputProps={{
                                type: showOTP ? "" : "password",
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {showOTP ? (
                                      <VisibilityIcon
                                        onClick={() => setShowOtp(!showOTP)}
                                        sx={styles.inputIconStyle}
                                      />
                                    ) : (
                                      <VisibilityOffIcon
                                        onClick={() => setShowOtp(!showOTP)}
                                        sx={styles.inputIconStyle}
                                      />
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </GridItem>
                        )}
                        <GridItem lg={12} md={12} sm={12} xs={12}>
                          {(isError || renderErrorFromSendotpAPI) && (
                            <p style={{ color: "#d51035" }}>{errorMessage}</p>
                          )}
                        </GridItem>
                        <GridItem lg={12} md={12} sm={12} xs={12}>
                          <Button
                            type="submit"
                            primary
                            dashboard
                            size="small"
                            loading={isLoginCompleted}
                            disabled={isLoginCompleted}
                          >
                            Login
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </Form>
                  )}
                </Formik>
              </>
            )}
          </Box>
        </Card>
      </Stack>
    </>
  );
}
export default LoginPage;
